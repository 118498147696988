<template>
     <div>
          <customHeader title="Notícias" subtitle="Não entre em campo com o seu time desfalcado! Veja as últimas notícias de cada clube." :mobile="mobile"></customHeader>
          <div class="container">
               <div class="row">
                    <div id="clubs" class="col-12 text-center">
                         <template v-for="(idClube,i) in clubes" >
                              <img v-if="data" :src="data[idClube].escudo" class="img img-fluid escudo" 
                                   @click="currentClub = idClube" :class="[currentClub == idClube ? 'currentClub' : '']" :key="idClube">
                              <br v-if="mobile && (i+1)%5==0" :key="idClube"/>
                         </template>
                         <hr>
                    </div>
                    <template v-if="currentClub != null">        
                         <div :class="[mobile ? 'col-10' : 'col-6']" id="iframe_twitter" style="margin: 0 auto;">
                              <a class="twitter-timeline" :href="data[currentClub].url_setorista"></a>
                         </div>
                    </template>
                    <template v-else>
                         <div class="col text-center">
                              <h3 class="mt-4">Escolha um time</h3>
                         </div>
                    </template>
               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';

     export default {
          name: 'Noticias',
          components:{
               customHeader
          },
          data(){
               return{
                    //Dados via API
                    data:null,
                    clubes: null,

                    //Clube selecionado
                    currentClub:null,

                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          watch:{
               currentClub: function () {
                    this.reload_twitter_iframe();
               }
          },
          created(){
               //Verificação mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               var vm = this;

               this.$http
               // .get(this.$_PATH+'/twitter/clubes/') 
               .get(this.$API+'twitter/clubes')
               .then(response => {
                    vm.clubes = response.data;
               })
               .catch(function (error) {
                    console.log(error);
               });

               this.$http
               // .get(this.$_PATH+'/twitter/') 
               .get(this.$API+'twitter')
               .then(response => {
                   vm.data = response.data;
               })
               .catch(function (error) {
                    console.log(error);
               });


          },
          methods:{
               //Recarrega o iframe do twitter baseado no clube selecionado "currentClub"
               reload_twitter_iframe(){
                    $("#twitter-wjs").remove();
                    $("#iframe_twitter").html('');

                    setTimeout(function(){
                         window.twttr = (function (d,s,id) {
                              
                              var e = d.getElementsByTagName(s);
                              e[0].parentNode.removeChild(e[0]);
                              var t, js, fjs = d.getElementsByTagName(s)[0];
                              if (d.getElementById(id)) return; js=d.createElement(s); js.id=id;
                              js.src="https://platform.twitter.com/widgets.js";
                              fjs.parentNode.insertBefore(js, fjs);

                              return window.twttr || (t = { _e: [], ready: function(f){ t._e.push(f) } });

                         }(document, "script", "twitter-wjs"));
                    },100);

                    $("#iframe_twitter").html('<a class="twitter-timeline" href="'+this.data[this.currentClub].url_setorista+'"></a>');
               },
          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     @import '@/assets/scss/news.scss';

</style>
